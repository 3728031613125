import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const PushDiv = styled(motion.div)``;

export default function Push({ isPush, setPushOut, text }) {
  console.log(text);
  setTimeout(() => {
    setPushOut();
  }, 1000);
  return (
    <AnimatePresence>
      {isPush && (
        <PushDiv
          initial={{ y: 50 }}
          animate={{ y: isPush ? 0 : 50 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={isPush ? 'fixed w-full px-[16px] bottom-[40px]' : 'hidden'}
        >
          <div className='bg-[#6E7487] w-full flex items-center justify-center py-[22.5px] rounded-[16px]'>
            <div>
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.9689 7.36382C23.58 4.17722 20.7824 1.3796 17.5958 1.99074L8.44673 3.74539C6.45789 4.12681 4.86735 5.63226 4.36202 7.58843C3.35311 11.494 6.97515 15.0635 10.8642 13.9198C10.9716 13.8882 11.0714 13.988 11.0398 14.0954C9.89617 17.9845 13.4656 21.6065 17.3712 20.5976C19.3274 20.0923 20.8328 18.5017 21.2142 16.5129L22.9689 7.36382Z'
                  fill='#04C357'
                />
                <path
                  d='M5.44454 16.3331C5.73744 16.0402 5.73744 15.5653 5.44454 15.2725C5.15165 14.9796 4.67678 14.9796 4.38388 15.2725L2.96967 16.6867C2.67678 16.9796 2.67678 17.4544 2.96967 17.7473C3.26256 18.0402 3.73744 18.0402 4.03033 17.7473L5.44454 16.3331Z'
                  fill='#04C357'
                />
                <path
                  d='M8.98008 15.9796C9.27297 16.2725 9.27297 16.7473 8.98008 17.0402L7.56586 18.4544C7.27297 18.7473 6.7981 18.7473 6.5052 18.4544C6.21231 18.1615 6.21231 17.6867 6.5052 17.3938L7.91942 15.9796C8.21231 15.6867 8.68718 15.6867 8.98008 15.9796Z'
                  fill='#04C357'
                />
                <path
                  d='M9.68718 20.5758C9.98008 20.2829 9.98008 19.808 9.68718 19.5151C9.39429 19.2222 8.91942 19.2222 8.62652 19.5151L7.21231 20.9293C6.91942 21.2222 6.91942 21.6971 7.21231 21.99C7.5052 22.2829 7.98008 22.2829 8.27297 21.99L9.68718 20.5758Z'
                  fill='#04C357'
                />
              </svg>
            </div>
            <div className='text-[#FEFEFE] font-semibold text-[16px] leading-[22px] ml-[10px]'>
              {text}
            </div>
          </div>
        </PushDiv>
      )}
    </AnimatePresence>
  );
}
