import Nav from '@/components/Nav';
import Title from '@/components/Title';
import { cls } from '@/libs/utils';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Item = styled(motion.div)``;

let data = {
  victory: [
    {
      id: 1,
      name: '김코끼리',
      gift: false
    },
    {
      id: 2,
      name: '최나무',
      gift: true
    },
    {
      id: 3,
      name: '홍기러기',
      gift: false
    },
    {
      id: 4,
      name: '민호랑이',
      gift: true
    }
  ],
  tie: [
    {
      id: 5,
      name: '추호경'
    },
    {
      id: 6,
      name: '정재진'
    }
  ],
  defeat: [
    {
      id: 7,
      name: '문우리',
      gift: false
    },
    {
      id: 8,
      name: '박힘찬',
      gift: true
    }
  ]
};

export default function Info() {
  const [type, setType] = useState('victory');
  const [checked, setChecked] = useState(false);
  const [victoryList, setVictoryList] = useState(data.victory);
  const [tieList] = useState(data.tie);
  const [defeatList, setDefeatList] = useState(data.defeat);
  const checkHandler = ({ target }) => {
    setChecked(target.checked);
  };
  useEffect(() => {
    if (checked) {
      setVictoryList(victoryList.filter((arr) => arr.gift === false));
      setDefeatList(defeatList.filter((arr) => arr.gift === false));
    } else {
      setVictoryList(data.victory);
      setDefeatList(data.defeat);
    }
  }, [checked, victoryList, defeatList]);
  return (
    <>
      <div className='px-[16px] w-screen h-screen overflow-scroll pb-[62px]'>
        <Title title={'마이페이지'} />
        {/* 프로필 */}
        <div className='flex flex-col items-center mt-[24px]'>
          <svg
            width='64'
            height='60'
            viewBox='0 0 64 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM2.4 30C2.4 45.2431 14.7569 57.6 30 57.6C45.2431 57.6 57.6 45.2431 57.6 30C57.6 14.7569 45.2431 2.4 30 2.4C14.7569 2.4 2.4 14.7569 2.4 30Z'
              fill='#04C357'
            />
            <g clip-path='url(#clip0_780_2641)'>
              <path
                d='M30 58C45.464 58 58 45.464 58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58Z'
                fill='#050409'
              />
              <mask
                id='mask0_780_2641'
                maskUnits='userSpaceOnUse'
                x='2'
                y='2'
                width='56'
                height='56'
              >
                <path
                  d='M30 58C45.464 58 58 45.464 58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58Z'
                  fill='white'
                />
              </mask>
              <g mask='url(#mask0_780_2641)'>
                <path
                  d='M9.91406 52.0027C9.91406 52.0027 11.0675 15.7441 39.2572 15.7441C67.4468 15.7441 62.4333 59.769 62.4333 59.769L30.4399 64.3366L9.91406 51.9975'
                  fill='#6E7487'
                />
              </g>
              <path
                d='M31.4866 33.7877C32.3077 33.7877 32.9733 33.1221 32.9733 32.3011C32.9733 31.48 32.3077 30.8145 31.4866 30.8145C30.6656 30.8145 30 31.48 30 32.3011C30 33.1221 30.6656 33.7877 31.4866 33.7877Z'
                fill='black'
              />
              <path
                d='M38.0589 33.7877C38.8799 33.7877 39.5455 33.1221 39.5455 32.3011C39.5455 31.48 38.8799 30.8145 38.0589 30.8145C37.2379 30.8145 36.5723 31.48 36.5723 32.3011C36.5723 33.1221 37.2379 33.7877 38.0589 33.7877Z'
                fill='black'
              />
              <path
                d='M34.8033 38.3405C34.8033 37.8741 34.7008 37.3563 34.3061 36.9769C33.8344 36.5156 33.7576 36.29 32.7682 36.7104C32.3837 36.8744 32.235 37.3871 32.1786 37.8023C32.1428 38.0637 32.1479 38.2226 32.235 38.4892C32.3119 38.7302 32.1171 39.0429 31.5327 38.9865C29.6667 38.7968 30.3742 37.3922 30.3742 37.3922C30.3742 37.3922 29.1131 38.1099 30.7843 39.9041C31.3636 40.5244 32.2402 40.9498 33.0809 40.8781C34.6085 40.7499 34.8136 39.2069 34.8085 38.3457L34.8033 38.3405Z'
                fill='black'
              />
              <path
                d='M34.1263 38.3405C34.1263 37.8741 34.2288 37.3563 34.6236 36.9769C35.0952 36.5156 35.1721 36.29 36.1615 36.7104C36.5459 36.8744 36.6946 37.3871 36.751 37.8023C36.7869 38.0637 36.7817 38.2226 36.6946 38.4892C36.6177 38.7302 36.8125 39.0429 37.3969 38.9865C39.2629 38.7968 38.5555 37.3922 38.5555 37.3922C38.5555 37.3922 39.8165 38.1099 38.1454 39.9041C37.5661 40.5244 36.6895 40.9498 35.8488 40.8781C34.3211 40.7499 34.1161 39.2069 34.1212 38.3457L34.1263 38.3405Z'
                fill='black'
              />
            </g>
            <circle cx='52' cy='48' r='12' fill='#04C357' />
            <path
              d='M52.9408 53C52.6647 53 52.4408 53.2239 52.4408 53.5C52.4408 53.7762 52.6647 54 52.9408 54H56.9408C57.217 54 57.4408 53.7762 57.4408 53.5C57.4408 53.2239 57.217 53 56.9408 53H52.9408Z'
              fill='#FAFAFA'
            />
            <path
              d='M53.4702 43.0497L53.5302 43.0843C54.049 43.3838 54.4816 43.6336 54.7983 43.878C55.1326 44.136 55.398 44.4321 55.5091 44.8469C55.6203 45.2617 55.5385 45.6508 55.378 46.0414C55.2666 46.3125 55.1027 46.6172 54.9053 46.9647L54.4818 46.713L54.4763 46.7097L49.8594 44.0442L49.4277 43.7897C49.6277 43.449 49.808 43.158 49.9854 42.928C50.2434 42.5937 50.5396 42.3283 50.9543 42.2172C51.3691 42.1061 51.7583 42.1878 52.1489 42.3483C52.5189 42.5004 52.9515 42.7502 53.4702 43.0497Z'
              fill='#FAFAFA'
            />
            <path
              d='M48.9267 44.6552L46.6081 48.6712C46.4095 49.0144 46.2526 49.2858 46.1944 49.5944C46.1362 49.9031 46.1837 50.213 46.2437 50.6049L46.2599 50.7108C46.3707 51.4384 46.462 52.0381 46.5997 52.4929C46.7438 52.9685 46.9652 53.3802 47.4007 53.6316C47.8363 53.8831 48.3035 53.869 48.7874 53.7559C49.2501 53.6478 49.8152 53.4271 50.5006 53.1592L50.6005 53.1203C50.9699 52.9763 51.262 52.8624 51.5002 52.6577C51.7385 52.453 51.895 52.1814 52.093 51.8379L54.4061 47.8313L53.9735 47.5742L49.3536 44.9069L48.9267 44.6552Z'
              fill='#FAFAFA'
            />
            <defs>
              <clipPath id='clip0_780_2641'>
                <rect
                  width='56'
                  height='56'
                  fill='white'
                  transform='translate(2 2)'
                />
              </clipPath>
            </defs>
          </svg>
          <div className='text-[#EFEFF6] text-[20px] font-bold leading-[26px] mt-[24px]'>
            김디톡스
          </div>
          <div className='text-[#6E7487] text-[12px] font-medium border border-[#6E7487] py-[4px] px-[8px] rounded-[20px] mt-[8px] flex'>
            <span>남자</span>
            <div className='h-[12px] w-[1px] bg-[#6E7487] mx-[4px]'></div>
            <span>30대</span>
            <div className='h-[12px] w-[1px] bg-[#6E7487] mx-[4px]'></div>
            <span>직장인</span>
          </div>
        </div>
        {/* 승리/무승부/패배 */}
        <div className='flex w-full h-[51px] bg-[#28272B] justify-between p-[6px] gap-[11px] rounded-[6px] mt-[42px]'>
          <div
            onClick={() => setType('victory')}
            className='relative w-full flex items-center justify-center gap-[4px]'
          >
            {type === 'victory' ? (
              <Item
                className='absolute bg-[#050409] w-full h-full rounded-[6px]'
                layoutId='type'
              />
            ) : null}
            <span
              className={cls(
                'text-[#FEFEFE] font-medium text-[16px] leading-[22px] z-0',
                type !== 'victory' ? 'opacity-[0.2]' : ''
              )}
            >
              승리
            </span>
            <span
              className={cls(
                'text-[#04C357] font-medium text-[16px] leading-[22px] z-0',
                type !== 'victory' ? 'opacity-[0.2]' : ''
              )}
            >
              4
            </span>
          </div>
          <div
            onClick={() => setType('tie')}
            className='relative w-full flex items-center justify-center gap-[4px]'
          >
            {type === 'tie' ? (
              <Item
                className='absolute bg-[#050409] w-full h-full rounded-[6px]'
                layoutId='type'
              />
            ) : null}
            <span
              className={cls(
                'text-[#FEFEFE] font-medium text-[16px] leading-[22px] z-0',
                type !== 'tie' ? 'opacity-[0.2]' : ''
              )}
            >
              무승부
            </span>
            <span
              className={cls(
                'text-[#04C357] font-medium text-[16px] leading-[22px] z-0',
                type !== 'tie' ? 'opacity-[0.2]' : ''
              )}
            >
              00
            </span>
          </div>
          <div
            onClick={() => setType('defeat')}
            className='relative w-full flex items-center justify-center gap-[4px]'
          >
            {type === 'defeat' ? (
              <Item
                className='absolute bg-[#050409] w-full h-full rounded-[6px]'
                layoutId='type'
              />
            ) : null}
            <span
              className={cls(
                'text-[#FEFEFE] font-medium text-[16px] leading-[22px] z-0',
                type !== 'defeat' ? 'opacity-[0.2]' : ''
              )}
            >
              패배
            </span>
            <span
              className={cls(
                'text-[#04C357] font-medium text-[16px] leading-[22px] z-0',
                type !== 'defeat' ? 'opacity-[0.2]' : ''
              )}
            >
              00
            </span>
          </div>
        </div>
        {/* 토글버튼 */}
        <div
          className={
            type === 'tie'
              ? 'hidden'
              : 'flex justify-end items-center mt-[16.5px] gap-[8px] mb-[4px]'
          }
        >
          <div className='text-[#BEBDC4] font-medium text-[16px] leading-[22px]'>
            미보상만 보기
          </div>
          <label
            for='switch'
            class='relative inline-flex cursor-pointer items-center'
          >
            <input
              id='switch'
              type='checkbox'
              class='peer sr-only'
              checked={checked}
              onChange={(e) => checkHandler(e)}
            />
            <div class='block h-[24px] w-[48px] rounded-full bg-[#28272B] peer-checked:bg-[#04C357]'></div>
            <div class='absolute w-[14px] h-[14px] transition bg-[#6E7487] rounded-full dot left-1 peer-checked:translate-x-full peer-checked:bg-[#FEFEFE] peer-checked:h-[20px] peer-checked:w-[20px]'></div>
          </label>
        </div>
        {/* 대결 목록 */}
        {type === 'victory'
          ? victoryList.map((result) => (
              <Link
                to={`/result/${result.id}`}
                state={{ type: 'victory' }}
                className='flex gap-[16px] items-center py-[16px] mt-[12px]'
              >
                <div>
                  <svg
                    width='48'
                    height='48'
                    viewBox='0 0 48 48'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_782_4079)'>
                      <path
                        d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                        fill='#050409'
                      />
                      <mask
                        id='mask0_782_4079'
                        maskUnits='userSpaceOnUse'
                        x='0'
                        y='0'
                        width='48'
                        height='48'
                      >
                        <path
                          d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                          fill='white'
                        />
                      </mask>
                      <g mask='url(#mask0_782_4079)'>
                        <path
                          d='M6.78516 42.86C6.78516 42.86 7.77381 11.7812 31.9364 11.7812C56.099 11.7812 51.8016 49.5169 51.8016 49.5169L24.3787 53.4319L6.78516 42.8556'
                          fill='#6E7487'
                        />
                      </g>
                      <path
                        d='M25.2743 27.2477C25.978 27.2477 26.5485 26.6772 26.5485 25.9735C26.5485 25.2697 25.978 24.6992 25.2743 24.6992C24.5705 24.6992 24 25.2697 24 25.9735C24 26.6772 24.5705 27.2477 25.2743 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M30.9071 27.2477C31.6108 27.2477 32.1813 26.6772 32.1813 25.9735C32.1813 25.2697 31.6108 24.6992 30.9071 24.6992C30.2033 24.6992 29.6328 25.2697 29.6328 25.9735C29.6328 26.6772 30.2033 27.2477 30.9071 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M28.1171 31.1502C28.1171 30.7503 28.0293 30.3065 27.6909 29.9814C27.2867 29.5859 27.2208 29.3926 26.3727 29.7529C26.0432 29.8935 25.9157 30.3329 25.8674 30.6888C25.8367 30.9129 25.841 31.0491 25.9157 31.2776C25.9817 31.4841 25.8147 31.7521 25.3138 31.7038C23.7144 31.5412 24.3207 30.3373 24.3207 30.3373C24.3207 30.3373 23.2398 30.9524 24.6722 32.4903C25.1688 33.022 25.9201 33.3867 26.6408 33.3252C27.9502 33.2153 28.1259 31.8927 28.1215 31.1546L28.1171 31.1502Z'
                        fill='black'
                      />
                      <path
                        d='M27.5377 31.1502C27.5377 30.7503 27.6256 30.3065 27.9639 29.9814C28.3681 29.5859 28.4341 29.3926 29.2821 29.7529C29.6116 29.8935 29.7391 30.3329 29.7874 30.6888C29.8182 30.9129 29.8138 31.0491 29.7391 31.2776C29.6732 31.4841 29.8401 31.7521 30.341 31.7038C31.9405 31.5412 31.3341 30.3373 31.3341 30.3373C31.3341 30.3373 32.415 30.9524 30.9826 32.4903C30.486 33.022 29.7347 33.3867 29.0141 33.3252C27.7046 33.2153 27.5289 31.8927 27.5333 31.1546L27.5377 31.1502Z'
                        fill='black'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_782_4079'>
                        <rect width='48' height='48' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='text-[#BEBDC4] text-[16px] leading-[22px] font-medium'>
                  <div>
                    <span className='text-[#FEFEFE] font-bold'>
                      {result.name}
                    </span>
                    님과 대결!
                  </div>
                  <div className='flex mt-[8px]'>
                    <div className='mr-[4px]'>
                      {result.gift ? (
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M18.3327 10.4993C18.3327 15.1017 14.6017 18.8327 9.99935 18.8327C5.39698 18.8327 1.66602 15.1017 1.66602 10.4993C1.66602 5.89698 5.39698 2.16602 9.99935 2.16602C14.6017 2.16602 18.3327 5.89698 18.3327 10.4993ZM5.54498 11.6112C5.85142 11.4523 6.22864 11.5719 6.38753 11.8783C6.61178 12.3108 7.0783 12.8607 7.72577 13.301C8.36756 13.7373 9.15009 14.041 9.99935 14.041C10.8486 14.041 11.6311 13.7373 12.2729 13.301C12.9204 12.8607 13.3869 12.3108 13.6112 11.8783C13.7701 11.5719 14.1473 11.4523 14.4537 11.6112C14.7601 11.7701 14.8798 12.1473 14.7209 12.4537C14.3896 13.0927 13.7727 13.7927 12.9758 14.3346C12.1731 14.8804 11.1501 15.291 9.99935 15.291C8.84861 15.291 7.82558 14.8804 7.02292 14.3346C6.22595 13.7927 5.60914 13.0927 5.27784 12.4537C5.11894 12.1473 5.23855 11.7701 5.54498 11.6112ZM7.49935 7.99935C7.95959 7.99935 8.33268 7.62625 8.33268 7.16602C8.33268 6.70578 7.95959 6.33268 7.49935 6.33268C7.03911 6.33268 6.66602 6.70578 6.66602 7.16602C6.66602 7.62625 7.03911 7.99935 7.49935 7.99935ZM12.4993 7.99935C12.9596 7.99935 13.3327 7.62625 13.3327 7.16602C13.3327 6.70578 12.9596 6.33268 12.4993 6.33268C12.0391 6.33268 11.666 6.70578 11.666 7.16602C11.666 7.62625 12.0391 7.99935 12.4993 7.99935Z'
                            fill='#46B86F'
                          />
                        </svg>
                      ) : (
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M18.3327 10.4993C18.3327 15.1017 14.6017 18.8327 9.99935 18.8327C5.39698 18.8327 1.66602 15.1017 1.66602 10.4993C1.66602 5.89698 5.39698 2.16602 9.99935 2.16602C14.6017 2.16602 18.3327 5.89698 18.3327 10.4993ZM7.72577 13.5311C7.0783 13.9713 6.61178 14.5212 6.38753 14.9537C6.22864 15.2601 5.85142 15.3798 5.54498 15.2209C5.23855 15.062 5.11894 14.6848 5.27784 14.3783C5.60914 13.7394 6.22595 13.0393 7.02292 12.4974C7.82558 11.9516 8.84861 11.541 9.99935 11.541C11.1501 11.541 12.1731 11.9516 12.9758 12.4974C13.7727 13.0393 14.3896 13.7394 14.7209 14.3783C14.8798 14.6848 14.7601 15.062 14.4537 15.2209C14.1473 15.3798 13.7701 15.2601 13.6112 14.9537C13.3869 14.5212 12.9204 13.9713 12.2729 13.5311C11.6311 13.0947 10.8486 12.791 9.99935 12.791C9.15009 12.791 8.36756 13.0947 7.72577 13.5311ZM7.49935 7.99935C7.95959 7.99935 8.33268 7.62625 8.33268 7.16602C8.33268 6.70578 7.95959 6.33268 7.49935 6.33268C7.03911 6.33268 6.66602 6.70578 6.66602 7.16602C6.66602 7.62625 7.03911 7.99935 7.49935 7.99935ZM12.4993 7.99935C12.9596 7.99935 13.3327 7.62625 13.3327 7.16602C13.3327 6.70578 12.9596 6.33268 12.4993 6.33268C12.0391 6.33268 11.666 6.70578 11.666 7.16602C11.666 7.62625 12.0391 7.99935 12.4993 7.99935Z'
                            fill='#F7573E'
                          />
                        </svg>
                      )}
                    </div>
                    {result.gift
                      ? '보상을 받았어요!'
                      : '보상을 아직 못 받았어요!'}
                  </div>
                </div>
              </Link>
            ))
          : type === 'tie'
          ? tieList.map((result) => (
              <Link
                to={`/result/${result.id}`}
                state={{ type: 'tie' }}
                className='flex gap-[16px] items-center py-[16px] mt-[12px]'
              >
                <div>
                  <svg
                    width='48'
                    height='48'
                    viewBox='0 0 48 48'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_782_4079)'>
                      <path
                        d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                        fill='#050409'
                      />
                      <mask
                        id='mask0_782_4079'
                        maskUnits='userSpaceOnUse'
                        x='0'
                        y='0'
                        width='48'
                        height='48'
                      >
                        <path
                          d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                          fill='white'
                        />
                      </mask>
                      <g mask='url(#mask0_782_4079)'>
                        <path
                          d='M6.78516 42.86C6.78516 42.86 7.77381 11.7812 31.9364 11.7812C56.099 11.7812 51.8016 49.5169 51.8016 49.5169L24.3787 53.4319L6.78516 42.8556'
                          fill='#6E7487'
                        />
                      </g>
                      <path
                        d='M25.2743 27.2477C25.978 27.2477 26.5485 26.6772 26.5485 25.9735C26.5485 25.2697 25.978 24.6992 25.2743 24.6992C24.5705 24.6992 24 25.2697 24 25.9735C24 26.6772 24.5705 27.2477 25.2743 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M30.9071 27.2477C31.6108 27.2477 32.1813 26.6772 32.1813 25.9735C32.1813 25.2697 31.6108 24.6992 30.9071 24.6992C30.2033 24.6992 29.6328 25.2697 29.6328 25.9735C29.6328 26.6772 30.2033 27.2477 30.9071 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M28.1171 31.1502C28.1171 30.7503 28.0293 30.3065 27.6909 29.9814C27.2867 29.5859 27.2208 29.3926 26.3727 29.7529C26.0432 29.8935 25.9157 30.3329 25.8674 30.6888C25.8367 30.9129 25.841 31.0491 25.9157 31.2776C25.9817 31.4841 25.8147 31.7521 25.3138 31.7038C23.7144 31.5412 24.3207 30.3373 24.3207 30.3373C24.3207 30.3373 23.2398 30.9524 24.6722 32.4903C25.1688 33.022 25.9201 33.3867 26.6408 33.3252C27.9502 33.2153 28.1259 31.8927 28.1215 31.1546L28.1171 31.1502Z'
                        fill='black'
                      />
                      <path
                        d='M27.5377 31.1502C27.5377 30.7503 27.6256 30.3065 27.9639 29.9814C28.3681 29.5859 28.4341 29.3926 29.2821 29.7529C29.6116 29.8935 29.7391 30.3329 29.7874 30.6888C29.8182 30.9129 29.8138 31.0491 29.7391 31.2776C29.6732 31.4841 29.8401 31.7521 30.341 31.7038C31.9405 31.5412 31.3341 30.3373 31.3341 30.3373C31.3341 30.3373 32.415 30.9524 30.9826 32.4903C30.486 33.022 29.7347 33.3867 29.0141 33.3252C27.7046 33.2153 27.5289 31.8927 27.5333 31.1546L27.5377 31.1502Z'
                        fill='black'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_782_4079'>
                        <rect width='48' height='48' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='text-[#BEBDC4] text-[16px] leading-[22px] font-medium'>
                  <div>
                    <span className='text-[#FEFEFE] font-bold'>
                      {result.name}
                    </span>
                    님과 대결!
                  </div>
                  <div className='flex mt-[8px]'>
                    <div className='mr-[4px]'>
                      <svg
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M18.3327 10.4993C18.3327 15.1017 14.6017 18.8327 9.99935 18.8327C5.39698 18.8327 1.66602 15.1017 1.66602 10.4993C1.66602 5.89698 5.39698 2.16602 9.99935 2.16602C14.6017 2.16602 18.3327 5.89698 18.3327 10.4993ZM6.04102 13.8327C6.04102 13.4875 6.32084 13.2077 6.66602 13.2077H13.3327C13.6779 13.2077 13.9577 13.4875 13.9577 13.8327C13.9577 14.1779 13.6779 14.4577 13.3327 14.4577H6.66602C6.32084 14.4577 6.04102 14.1779 6.04102 13.8327ZM7.49935 8.83268C7.95959 8.83268 8.33268 8.45959 8.33268 7.99935C8.33268 7.53911 7.95959 7.16602 7.49935 7.16602C7.03911 7.16602 6.66602 7.53911 6.66602 7.99935C6.66602 8.45959 7.03911 8.83268 7.49935 8.83268ZM12.4993 8.83268C12.9596 8.83268 13.3327 8.45959 13.3327 7.99935C13.3327 7.53911 12.9596 7.16602 12.4993 7.16602C12.0391 7.16602 11.666 7.53911 11.666 7.99935C11.666 8.45959 12.0391 8.83268 12.4993 8.83268Z'
                          fill='#FECC54'
                        />
                      </svg>
                    </div>
                    대결에서 비겼어요.
                  </div>
                </div>
              </Link>
            ))
          : type === 'defeat'
          ? defeatList.map((result) => (
              <Link
                to={`/result/${result.id}`}
                state={{ type: 'defeat' }}
                className='flex gap-[16px] items-center py-[16px] mt-[12px]'
              >
                <div>
                  <svg
                    width='48'
                    height='48'
                    viewBox='0 0 48 48'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_782_4079)'>
                      <path
                        d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                        fill='#050409'
                      />
                      <mask
                        id='mask0_782_4079'
                        maskUnits='userSpaceOnUse'
                        x='0'
                        y='0'
                        width='48'
                        height='48'
                      >
                        <path
                          d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                          fill='white'
                        />
                      </mask>
                      <g mask='url(#mask0_782_4079)'>
                        <path
                          d='M6.78516 42.86C6.78516 42.86 7.77381 11.7812 31.9364 11.7812C56.099 11.7812 51.8016 49.5169 51.8016 49.5169L24.3787 53.4319L6.78516 42.8556'
                          fill='#6E7487'
                        />
                      </g>
                      <path
                        d='M25.2743 27.2477C25.978 27.2477 26.5485 26.6772 26.5485 25.9735C26.5485 25.2697 25.978 24.6992 25.2743 24.6992C24.5705 24.6992 24 25.2697 24 25.9735C24 26.6772 24.5705 27.2477 25.2743 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M30.9071 27.2477C31.6108 27.2477 32.1813 26.6772 32.1813 25.9735C32.1813 25.2697 31.6108 24.6992 30.9071 24.6992C30.2033 24.6992 29.6328 25.2697 29.6328 25.9735C29.6328 26.6772 30.2033 27.2477 30.9071 27.2477Z'
                        fill='black'
                      />
                      <path
                        d='M28.1171 31.1502C28.1171 30.7503 28.0293 30.3065 27.6909 29.9814C27.2867 29.5859 27.2208 29.3926 26.3727 29.7529C26.0432 29.8935 25.9157 30.3329 25.8674 30.6888C25.8367 30.9129 25.841 31.0491 25.9157 31.2776C25.9817 31.4841 25.8147 31.7521 25.3138 31.7038C23.7144 31.5412 24.3207 30.3373 24.3207 30.3373C24.3207 30.3373 23.2398 30.9524 24.6722 32.4903C25.1688 33.022 25.9201 33.3867 26.6408 33.3252C27.9502 33.2153 28.1259 31.8927 28.1215 31.1546L28.1171 31.1502Z'
                        fill='black'
                      />
                      <path
                        d='M27.5377 31.1502C27.5377 30.7503 27.6256 30.3065 27.9639 29.9814C28.3681 29.5859 28.4341 29.3926 29.2821 29.7529C29.6116 29.8935 29.7391 30.3329 29.7874 30.6888C29.8182 30.9129 29.8138 31.0491 29.7391 31.2776C29.6732 31.4841 29.8401 31.7521 30.341 31.7038C31.9405 31.5412 31.3341 30.3373 31.3341 30.3373C31.3341 30.3373 32.415 30.9524 30.9826 32.4903C30.486 33.022 29.7347 33.3867 29.0141 33.3252C27.7046 33.2153 27.5289 31.8927 27.5333 31.1546L27.5377 31.1502Z'
                        fill='black'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_782_4079'>
                        <rect width='48' height='48' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='text-[#BEBDC4] text-[16px] leading-[22px] font-medium'>
                  <div>
                    <span className='text-[#FEFEFE] font-bold'>
                      {result.name}
                    </span>
                    님과 대결!
                  </div>
                  <div className='flex mt-[8px]'>
                    <div className='mr-[4px]'>
                      {result.gift ? (
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M18.3327 10.4993C18.3327 15.1017 14.6017 18.8327 9.99935 18.8327C5.39698 18.8327 1.66602 15.1017 1.66602 10.4993C1.66602 5.89698 5.39698 2.16602 9.99935 2.16602C14.6017 2.16602 18.3327 5.89698 18.3327 10.4993ZM5.54498 11.6112C5.85142 11.4523 6.22864 11.5719 6.38753 11.8783C6.61178 12.3108 7.0783 12.8607 7.72577 13.301C8.36756 13.7373 9.15009 14.041 9.99935 14.041C10.8486 14.041 11.6311 13.7373 12.2729 13.301C12.9204 12.8607 13.3869 12.3108 13.6112 11.8783C13.7701 11.5719 14.1473 11.4523 14.4537 11.6112C14.7601 11.7701 14.8798 12.1473 14.7209 12.4537C14.3896 13.0927 13.7727 13.7927 12.9758 14.3346C12.1731 14.8804 11.1501 15.291 9.99935 15.291C8.84861 15.291 7.82558 14.8804 7.02292 14.3346C6.22595 13.7927 5.60914 13.0927 5.27784 12.4537C5.11894 12.1473 5.23855 11.7701 5.54498 11.6112ZM7.49935 7.99935C7.95959 7.99935 8.33268 7.62625 8.33268 7.16602C8.33268 6.70578 7.95959 6.33268 7.49935 6.33268C7.03911 6.33268 6.66602 6.70578 6.66602 7.16602C6.66602 7.62625 7.03911 7.99935 7.49935 7.99935ZM12.4993 7.99935C12.9596 7.99935 13.3327 7.62625 13.3327 7.16602C13.3327 6.70578 12.9596 6.33268 12.4993 6.33268C12.0391 6.33268 11.666 6.70578 11.666 7.16602C11.666 7.62625 12.0391 7.99935 12.4993 7.99935Z'
                            fill='#46B86F'
                          />
                        </svg>
                      ) : (
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M18.3327 10.4993C18.3327 15.1017 14.6017 18.8327 9.99935 18.8327C5.39698 18.8327 1.66602 15.1017 1.66602 10.4993C1.66602 5.89698 5.39698 2.16602 9.99935 2.16602C14.6017 2.16602 18.3327 5.89698 18.3327 10.4993ZM7.72577 13.5311C7.0783 13.9713 6.61178 14.5212 6.38753 14.9537C6.22864 15.2601 5.85142 15.3798 5.54498 15.2209C5.23855 15.062 5.11894 14.6848 5.27784 14.3783C5.60914 13.7394 6.22595 13.0393 7.02292 12.4974C7.82558 11.9516 8.84861 11.541 9.99935 11.541C11.1501 11.541 12.1731 11.9516 12.9758 12.4974C13.7727 13.0393 14.3896 13.7394 14.7209 14.3783C14.8798 14.6848 14.7601 15.062 14.4537 15.2209C14.1473 15.3798 13.7701 15.2601 13.6112 14.9537C13.3869 14.5212 12.9204 13.9713 12.2729 13.5311C11.6311 13.0947 10.8486 12.791 9.99935 12.791C9.15009 12.791 8.36756 13.0947 7.72577 13.5311ZM7.49935 7.99935C7.95959 7.99935 8.33268 7.62625 8.33268 7.16602C8.33268 6.70578 7.95959 6.33268 7.49935 6.33268C7.03911 6.33268 6.66602 6.70578 6.66602 7.16602C6.66602 7.62625 7.03911 7.99935 7.49935 7.99935ZM12.4993 7.99935C12.9596 7.99935 13.3327 7.62625 13.3327 7.16602C13.3327 6.70578 12.9596 6.33268 12.4993 6.33268C12.0391 6.33268 11.666 6.70578 11.666 7.16602C11.666 7.62625 12.0391 7.99935 12.4993 7.99935Z'
                            fill='#F7573E'
                          />
                        </svg>
                      )}
                    </div>
                    {result.gift
                      ? '보상을 보냈어요!'
                      : '보상을 아직 안 보냈어요!'}
                  </div>
                </div>
              </Link>
            ))
          : null}
      </div>
      <Nav />
    </>
  );
}
